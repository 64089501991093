import React from 'react'
import styled from 'styled-components'
import {GatsbyImage, getImage} from 'gatsby-plugin-image'
import PropTypes from 'prop-types'

import {Video} from '../elements'

const BrowserWindow = ({
  image,
  video,
  alt,
  height,
  colorTheme,
  enlargedTopBar,
}) => (
  <Wrapper>
    <Header colorTheme={colorTheme} enlargedTopBar={enlargedTopBar}>
      <Circle enlargedTopBar={enlargedTopBar} />
      <Circle enlargedTopBar={enlargedTopBar} />
      <Circle enlargedTopBar={enlargedTopBar} />
    </Header>
    {image && (
      <GatsbyImage
        image={getImage(image)}
        alt={alt}
        objectPosition="top left"
        style={{
          height: height,
        }}
      />
    )}
    {video && (
      <Video autoPlay muted loop>
        <source src={video} type="video/mp4" />
      </Video>
    )}
    <Hover className="theme-card__overlay">
      <span>View</span>
    </Hover>
  </Wrapper>
)

export default BrowserWindow

BrowserWindow.propTypes = {
  alt: PropTypes.string,
  image: PropTypes.object,
  video: PropTypes.string,
  height: PropTypes.string,
  colorTheme: PropTypes.oneOf(['light', 'dark']),
  enlargedTopBar: PropTypes.bool,
}

const Wrapper = styled.div`
  border-radius: 6px;
  overflow: hidden;
  width: 100%;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.22);
  position: relative;
  max-height: 100%;
`

const Header = styled.div`
  background: ${props =>
    props.colorTheme === 'light' ? props.theme.color.grey.base : '#383838'};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: ${props => (props.enlargedTopBar ? '5px 8px' : '4px 8px')};
`

const Circle = styled.div`
  width: ${props => (props.enlargedTopBar ? '7px' : '5px')};
  height: ${props => (props.enlargedTopBar ? '7px' : '5px')};
  border-radius: 100%;
  background-color: #c4c4c4;
  margin-right: 4px;
`

const Hover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 1.8rem;
  opacity: 0;

  span {
    background: rgb(59, 42, 102, 70%);
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
  }
`
