import React from 'react'
import styled, {css} from 'styled-components'
import PropTypes from 'prop-types'

import {BoxedContainer} from '../elements'

const PageHeader = ({title, subtitle, subheader}) => (
  <Wrapper subheader={subheader}>
    <BoxedContainer>
      <h1>{title}</h1>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </BoxedContainer>
  </Wrapper>
)

export default PageHeader

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  subheader: PropTypes.bool,
}

const Wrapper = styled.div`
  background: ${props => props.theme.color.grey.lighter};
  padding: 60px 0;

  h1 {
    color: ${props =>
      props.subheader
        ? props.theme.color.text.base
        : props.theme.color.primary.base};

    ${props =>
      props.subheader &&
      css`
        @media (min-width: ${props.theme.breakpoints.lg}) {
          font-size: 3.6rem;
        }
      `}
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    padding: 70px 0;
  }
`

const Subtitle = styled.p`
  max-width: 750px;
  font-size: 1.8rem;
  margin: 10px 0 0;
  color: ${props => props.theme.color.text.lightest};

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    font-size: 2rem;
  }
`
