import React from 'react'
import PropTypes from 'prop-types'
import {Helmet} from 'react-helmet'
import {useStaticQuery, graphql} from 'gatsby'
import {useLocation} from '@reach/router'

const MetaData = ({data, title, description, image, article, canonical}) => {
  const {pathname} = useLocation()
  const {site, ghostSettings} = useStaticQuery(query)

  title = `
    ${
      title ||
      data?.meta_title ||
      data?.title ||
      ghostSettings?.meta_title ||
      site.siteMetadata.title
    } | ${site.siteMetadata.companyName}`
  description =
    description ||
    data?.meta_description ||
    data?.excerpt ||
    ghostSettings?.description ||
    site.siteMetadata.description
  const pageUrl = `${site.siteMetadata.siteUrl}${pathname}`
  const twitterUsername = site.siteMetadata.social.twitter
  image =
    image ||
    data?.featureImageSharp?.publicURL ||
    data?.feature_image ||
    ghostSettings?.coverImageSharp?.publicURL
  const imageUrl = new URL(image, site.siteMetadata.siteUrl)
  canonical =
    canonical ||
    data?.canonical_url ||
    (pageUrl.endsWith('/') ? pageUrl : pageUrl + '/')

  return (
    <Helmet title={title}>
      <meta name="description" content={description} />
      {image && <meta name="image" content={imageUrl.href} />}
      {canonical && <link rel="canonical" href={canonical} />}

      {pageUrl && <meta property="og:url" content={pageUrl} />}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {title && <meta property="og:title" content={title} />}
      {site.siteMetadata.title && (
        <meta property="og:site_name" content={site.siteMetadata.title} />
      )}
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={imageUrl.href} />}

      <meta name="twitter:card" content="summary_large_image" />
      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}
      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="twitter:description" content={description} />}
      {image && <meta name="twitter:image" content={imageUrl.href} />}
    </Helmet>
  )
}

export default MetaData

MetaData.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
  canonical: PropTypes.string,
}

MetaData.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
  canonical: null,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        companyName
        title
        description
        siteUrl
        social {
          twitter
        }
      }
    }
    ghostSettings {
      ...GhostSettingsFields
    }
  }
`
